import { createSignal, For, Show } from "solid-js";
import { CloseModalButton } from "../modal";
import { PhosphorIcon } from "~/widgets/icons";
import { SetStoreFunction } from "solid-js/store";
import { PlutoStore } from "~/routes/gifts/new";

const suggestions = [
  "Gift for my mom’s birthday",
  "Gift for my dad’s birthday",
  "Gift for wedding anniversary",
  "Gift for new year",
];

export function FullScreenInputModal(props: {
  state: PlutoStore;
  setState: SetStoreFunction<PlutoStore>;
  handleNewMessage: () => Promise<void>;
  closeModal: () => void;
  initialCount: number;
}) {
  const [characterCount, setCharacterCount] = createSignal(
    props.initialCount || 0
  );
  const [isSuggestionModalOpen, setIsSuggestionModalOpen] = createSignal(
    props.initialCount > 0 ? false : true
  );
  const maxCharLimit = 500;

  return (
    <div class="relative h-lvh w-full pt-14">
      <div class="mb-6 ml-2 mt-2">
        <CloseModalButton />
      </div>
      <textarea
        class={`no-scrollbar mx-2 block ${characterCount() ? "h-[80%]" : ""} min-h-[73px] w-[95%] resize-none overflow-y-auto border-none bg-transparent text-h4 font-normal text-white outline-transparent placeholder:text-h4 placeholder:font-normal placeholder:text-textNormal`}
        placeholder="Tell me who is this gift for & the occasion..."
        value={props.state.userMessage}
        onInput={(e) => {
          setCharacterCount(e.target.value.length);
          setIsSuggestionModalOpen(e.target.value.length === 0);
        }}
        onChange={(e) => {
          const message = e.currentTarget.value;
          if (message.length <= maxCharLimit) {
            props.setState("userMessage", message);
            setCharacterCount(message.length);
          }
        }}
      ></textarea>
      <Show when={isSuggestionModalOpen()}>
        <div class="mx-2 flex flex-col gap-1.5 border-t border-basePrimaryMedium pt-5">
          <For each={suggestions}>
            {(suggestion) => {
              return (
                <div
                  class="flex cursor-pointer items-center justify-between"
                  onClick={() => {
                    props.setState("userMessage", suggestion);
                    setCharacterCount(suggestion.length);
                    setIsSuggestionModalOpen(false);
                  }}
                >
                  <p class="text-normal text-textNormal hover:text-white">
                    {suggestion}
                  </p>
                  <PhosphorIcon
                    name="arrow-up-left"
                    fontSize={20}
                    size="bold"
                    class="text-baseSecondaryLight hover:text-white"
                  />
                </div>
              );
            }}
          </For>
        </div>
      </Show>
      <div class="absolute bottom-5 mx-2 w-[95%]">
        <div class="flex items-center justify-between">
          <div
            class={`text-medium ${
              characterCount() > maxCharLimit
                ? "text-errorDark"
                : "text-textNormal"
            }`}
          >
            {characterCount()}/{maxCharLimit}
          </div>
          <button
            onClick={async () => {
              props.closeModal();
              props.handleNewMessage();
            }}
            class={`flex h-9 w-9 items-center justify-center rounded-lg disabled:pointer-events-none disabled:opacity-50 ${
              characterCount() > 0 && characterCount() <= maxCharLimit
                ? "bg-white"
                : "bg-baseSecondaryDark"
            }`}
            disabled={characterCount() > maxCharLimit || characterCount() === 0}
          >
            <PhosphorIcon name="arrow-right" fontSize={20} size="bold" />
          </button>
        </div>
      </div>
    </div>
  );
}
