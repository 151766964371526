import { A } from "@solidjs/router";
import {
  Component,
  createEffect,
  createSignal,
  For,
  onCleanup,
  onMount,
  Show,
} from "solid-js";
import { createStore, SetStoreFunction } from "solid-js/store";
import {
  giftIcon,
  novaChatBubbleBtm,
  novaChatBubbleTop,
  novaLoadingBubble,
  novaStar,
  userCircle,
} from "~/assets/assets";
import { useModal } from "~/components/modal";
import { FullScreenInputModal } from "~/components/pluto/full_screen_input_modal";
import { usePluto } from "~/context/pluto";
import {
  createConversation,
  getGift,
  getPlutoCredits,
  sendNewMessage,
} from "~/server/apis/client_apis";
import { ChatDetails, ChatHistory, Credits } from "~/server/types/pluto";
import { ThreeDotLoader } from "~/widgets/button";
import { PhosphorIcon } from "~/widgets/icons";

export type PlutoStore = {
  chatDetails: ChatDetails | null;
  userMessage: string;
  isTextareaFocused: boolean;
  isFirstMessage: boolean;
  currentTextareaHeight: string;
  isMessageLoading: boolean;
  isPollLoading: boolean;
  startNewConversation: boolean;
};

export default function Pluto() {
  const [state, setState] = createStore<PlutoStore>({
    chatDetails: null,
    userMessage: "",
    isTextareaFocused: false,
    isFirstMessage: true,
    currentTextareaHeight: "auto",
    isMessageLoading: false,
    isPollLoading: false,
    startNewConversation: false,
  });

  const {
    plutoGifts,
    getNewGiftCard,
    fetchInitiatePlutoDetails,
    updateCredits,
  } = usePluto();

  onMount(async () => {
    if (
      !plutoGifts.credits &&
      !plutoGifts.conversations &&
      !plutoGifts.userName
    ) {
      fetchInitiatePlutoDetails();
    }
  });

  createEffect(() => {
    if (state.chatDetails?.status === "COMPLETED") {
      pollForGiftStatus();
    }
  });

  createEffect(async () => {
    if (plutoGifts.newGiftCardDetails) {
      setState("startNewConversation", true);
      const credits = await getPlutoCredits();
      updateCredits(credits);
    }
  });

  const pollForGiftStatus = () => {
    setState("isPollLoading", true);
    const intervalId = setInterval(async () => {
      try {
        const giftResponse = await getGift(state.chatDetails?.id!);
        if (giftResponse.status === "COMPLETED") {
          getNewGiftCard(giftResponse);
          setState("isPollLoading", false);
          clearInterval(intervalId);
        }
      } catch (error) {
        console.error("Error fetching gift status:", error);
        setState("isPollLoading", false);
      }
    }, 5000);

    onCleanup(() => clearInterval(intervalId));
  };

  const handleNewMessage = async () => {
    const trimmedMessage = state.userMessage.trim();
    if (!trimmedMessage) return;

    setState("isMessageLoading", true);
    try {
      if (state.chatDetails === null) {
        const initialSystemMessages: ChatHistory[] = [
          {
            sender: "system",
            content:
              "Hey there! I'm super excited to help you find the perfect gift! Can you share a little about the recipient—maybe their name, favorite things, or the special occasion? Let’s make this gift truly unforgettable!",
            timestamp: new Date().toISOString(),
          },
          {
            sender: "user",
            content: trimmedMessage,
            timestamp: new Date().toISOString(),
          },
        ];
        setState("chatDetails", (chatDetails) => ({
          ...chatDetails!,
          messages: initialSystemMessages,
        }));
        const conversationDetails = await createConversation();
        setState("chatDetails", () => ({
          id: conversationDetails.id,
          title: "",
          messages: initialSystemMessages,
          status: "INITIATED",
        }));
      }

      const updatedChat = await sendNewMessage(
        state.chatDetails!.id,
        trimmedMessage
      );
      setState("chatDetails", updatedChat);
      setState("userMessage", "");
      setState("currentTextareaHeight", "auto");
    } catch (error) {
      console.error("Failed to send message:", error);
    } finally {
      setState("isMessageLoading", false);
    }
  };

  return (
    <div class="m-auto flex h-dvh w-full flex-col md:w-[800px]">
      <Show
        when={state.chatDetails && state.chatDetails.messages.length > 1}
        fallback={
          <IntroCard
            state={state}
            setState={setState}
            handleNewMessage={handleNewMessage}
          />
        }
      >
        <Chat state={state} />
      </Show>
      <Show when={!state.chatDetails}>
        <CreditsDisplay credits={plutoGifts.credits} />
      </Show>
      <Show when={plutoGifts.credits?.available !== 0}>
        <Show
          when={plutoGifts.newGiftCardDetails && !state.startNewConversation}
          fallback={
            <MessageInput
              state={state}
              setState={setState}
              handleNewMessage={handleNewMessage}
            />
          }
        >
          <CompletedConversation />
        </Show>
      </Show>
    </div>
  );
}

interface CreditsDisplayProps {
  credits: Credits | null;
}

const CreditsDisplay: Component<CreditsDisplayProps> = (props) => {
  if (props.credits?.available === 0) {
    return (
      <div class="bg-basePrimaryDark p-3 text-center">
        <p class="text-mediumBold text-[#E0B827]">
          You've used up all your free gift creation
        </p>
        <p class="text-medium text-textNormal">
          To unlock 3 more, share any of the{" "}
          <A href="/gifts/profile" class="underline">
            previously created gifts
          </A>
        </p>
      </div>
    );
  }

  if (props.credits?.available === 1) {
    return (
      <div class="m-auto flex w-4/5 flex-col items-center justify-center text-center">
        <p class="text-mediumBold text-[#E0B827]">
          This is the last gift you can create
        </p>
        <p class="text-medium text-textNormal">
          To unlock 3 more, share any of the previously created gifts
        </p>
      </div>
    );
  }

  return (
    <div class="flex justify-center">
      <p class="text-medium text-baseSecondaryLight">
        {props.credits?.available} gift creations left
      </p>
    </div>
  );
};

const CompletedConversation: Component = () => (
  <div class="bg-basePrimaryDark px-2.5 py-3 text-center">
    <p class="text-medium text-textNormal">
      This conversation has been marked as completed.
    </p>
    <p class="text-medium text-textNormal">
      <A href="/gifts/new" class="underline">
        Start a new conversation
      </A>
    </p>
  </div>
);

const GiftPill: Component<{ text: string; onClick: () => void }> = (props) => (
  <button
    class="text-4xl mx-1.5 flex h-8 cursor-pointer items-center gap-2 whitespace-nowrap rounded-lg bg-basePrimaryDark px-2.5 py-3 text-medium text-white"
    onClick={props.onClick}
  >
    {props.text}
  </button>
);

const MarqueeRow: Component<{
  pills: { text: string }[];
  onPillClick: (text: string) => void;
}> = (props) => (
  <div class="relative flex overflow-x-hidden text-white">
    <For each={[1, 2]}>
      {(key) => (
        <div
          class={`flex whitespace-nowrap ${key === 2 ? "animate-marquee2 absolute top-0" : "animate-marquee"}`}
        >
          <For each={props.pills}>
            {(pill) => (
              <GiftPill
                text={pill.text}
                onClick={() => props.onPillClick(pill.text)}
              />
            )}
          </For>
        </div>
      )}
    </For>
  </div>
);

const IntroCard = ({
  state,
  setState,
  handleNewMessage,
}: {
  state: PlutoStore;
  setState: SetStoreFunction<PlutoStore>;
  handleNewMessage: () => Promise<void>;
}) => {
  const { openModal, closeModal } = useModal()!;

  const giftRows: { text: string }[][] = [
    [
      { text: "👩 Gift for my mom's birthday" },
      {
        text: "💍 Wedding gift for my friend",
      },
      {
        text: "👨 Wedding gift for my friend",
      },
    ],
    [
      { text: "🎅 Merry Christmas gift" },
      { text: "🗓️ Happy new year present" },
    ],
    [
      { text: "🗓️ new year present" },
      {
        text: "💍 Happy Wedding Anniversary gift",
      },
    ],
  ];

  const handlePillClick = (text: string) => {
    setState("userMessage", text);
    setState("isTextareaFocused", true);
    setState("isFirstMessage", true);

    openModal(
      () => (
        <FullScreenInputModal
          closeModal={closeModal}
          initialCount={text.length}
          state={state}
          setState={setState}
          handleNewMessage={handleNewMessage}
        />
      ),
      "w-screen max-h-screen dark",
      "dark:!bg-black border-none"
    );
  };

  return (
    <div class="flex flex-1 flex-col items-center justify-center gap-7">
      <div class="h-[100px] w-[100px] rounded-full bg-[#161616]"></div>
      <div>
        <p class="text-center text-smallBold uppercase tracking-[1.1px] text-baseSecondaryLight">
          Hi, I am Pluto
        </p>
        <h1 class="text-[28px] font-semibold text-white">
          Your AI gifting assistant
        </h1>
      </div>

      <div class="flex flex-col gap-3">
        <For each={giftRows}>
          {(row) => <MarqueeRow pills={row} onPillClick={handlePillClick} />}
        </For>
      </div>
    </div>
  );
};

const Chat = ({ state }: { state: PlutoStore }) => {
  const { plutoGifts } = usePluto();

  return (
    <div class="flex-1 overflow-y-auto">
      <div class="space-y-4 p-4 pt-16">
        <For each={state.chatDetails?.messages}>
          {(message) => <MessageBubble message={message} />}
        </For>
        <Show when={state.isMessageLoading}>
          <LoadingIndicator />
        </Show>
        <Show when={state.isPollLoading || plutoGifts.newGiftCardDetails}>
          <div class="flex items-start gap-2">
            <img src={novaStar} alt="nova" class="h-8 w-8 rounded-full" />
            <div class="flex flex-col gap-1">
              <Show when={!plutoGifts.newGiftCardDetails}>
                <div
                  class={`shadow-md relative max-w-[440px] rounded-[20px] 
                  rounded-tl-[4px] bg-plutoPurple
                  p-[18px] text-white`}
                >
                  <img
                    src={novaChatBubbleTop}
                    class="absolute right-0 top-0 rounded-tr-[20px]"
                  />
                  <img
                    src={novaChatBubbleBtm}
                    class="absolute bottom-0 left-0 rounded-bl-[20px]"
                  />
                  <p class="text-normal">
                    Creating gift, can take up to a second.
                  </p>
                </div>
                <div class="relative">
                  <img src={novaLoadingBubble} alt="" class="w-16" />
                  <div class="absolute left-4 top-4">
                    <ThreeDotLoader color="#999" />
                  </div>
                </div>
              </Show>

              <Show when={plutoGifts.newGiftCardDetails}>
                <div
                  class={`shadow-md relative max-w-[440px] rounded-[20px] 
                  rounded-tl-[4px] bg-plutoPurple
                  p-[18px] text-white`}
                >
                  <img
                    src={novaChatBubbleTop}
                    class="absolute right-0 top-0 rounded-tr-[20px]"
                  />
                  <img
                    src={novaChatBubbleBtm}
                    class="absolute bottom-0 left-0 rounded-bl-[20px]"
                  />
                  <div class="flex flex-col gap-3 text-normal">
                    <p class="text-normal">
                      Here’s the gift for{" "}
                      {
                        plutoGifts.newGiftCardDetails?.content.text.occasion
                          .recipientName
                      }
                      , hope they like it and you too.
                    </p>
                    <A
                      href={`/gifts/${plutoGifts.newGiftCardDetails?.id}`}
                      class="z-30 flex h-[44px] w-[127px] cursor-pointer items-center justify-center gap-1 rounded-[41px] bg-baseTertiaryMedium px-3"
                    >
                      <img src={giftIcon} alt="gift icon" class="pb-1" />
                      <p class="text-buttonMedium font-bold text-textDark">
                        View gift
                      </p>
                    </A>
                  </div>
                </div>
              </Show>
            </div>
          </div>
        </Show>
      </div>
    </div>
  );
};

const MessageBubble = (props: { message: ChatHistory }) => (
  <div
    class={`flex ${props.message.sender === "user" ? "justify-end" : "justify-start"}`}
  >
    <div
      class={`flex gap-2 ${props.message.sender === "user" ? "flex-row-reverse items-end" : "items-start"}`}
    >
      <img
        src={props.message.sender === "user" ? userCircle : novaStar}
        alt={`Avatar of ${props.message.sender}`}
        class="h-8 w-8 rounded-full"
      />
      <div class="flex flex-col gap-2">
        <div
          class={`relative max-w-[440px] rounded-[20px] p-[18px] text-white ${
            props.message.sender === "user"
              ? "rounded-br-[4px] border border-[#252525] bg-black"
              : "rounded-tl-[4px] bg-plutoPurple"
          } shadow-md`}
        >
          <Show when={props.message.sender === "system"}>
            <img
              src={novaChatBubbleTop}
              class="absolute right-0 top-0 rounded-tr-[20px]"
            />
            <img
              src={novaChatBubbleBtm}
              class="absolute bottom-0 left-0 rounded-bl-[20px]"
            />
          </Show>
          <p class="text-normal">{props.message.content}</p>
        </div>
      </div>
    </div>
  </div>
);

const LoadingIndicator = () => (
  <div class="flex items-start gap-2">
    <img src={novaStar} alt="nova" class="h-8 w-8 rounded-full" />
    <div class="relative">
      <img src={novaLoadingBubble} alt="" class="w-16" />
      <div class="absolute left-4 top-4">
        <ThreeDotLoader color="#999" />
      </div>
    </div>
  </div>
);

const MessageInput = (props: {
  state: PlutoStore;
  setState: SetStoreFunction<PlutoStore>;
  handleNewMessage: () => Promise<void>;
}) => {
  const maxCharLimit = 500;
  const [characterCount, setCharacterCount] = createSignal(0);

  const { openModal, closeModal } = useModal()!;

  const autoGrowTextarea = (event: InputEvent) => {
    const textarea = event.target as HTMLTextAreaElement;
    const newHeight = Math.min(textarea.scrollHeight, 168);
    props.setState("currentTextareaHeight", `${newHeight}px`);
    setCharacterCount(textarea.value.length);
  };

  const openFullScreenInput = () => {
    if (props.state.isFirstMessage) {
      openModal(
        () => (
          <FullScreenInputModal
            closeModal={closeModal}
            initialCount={characterCount()}
            state={props.state}
            setState={props.setState}
            handleNewMessage={props.handleNewMessage}
          />
        ),
        "w-screen max-h-screen dark",
        "dark:!bg-black border-none"
      );
    }
  };

  createEffect(() => {
    if (
      props.state.chatDetails &&
      props.state.chatDetails?.messages.length > 2
    ) {
      props.setState("isFirstMessage", false);
    }
  });

  return (
    <div class="mx-4 py-4 sm:mx-0">
      <div class="relative max-w-full">
        <textarea
          class={`text-sm no-scrollbar block max-h-40 w-full resize-none overflow-y-auto rounded-2xl border border-basePrimaryLight  bg-black p-3 pe-16 pl-5 text-white placeholder:text-baseSecondaryDark ${characterCount() > maxCharLimit ? "focus:border-errorDark" : "focus:border-baseTertiaryLight"} focus:ring-baseTertiaryLight disabled:pointer-events-none`}
          placeholder="Write about the gift receiver...."
          rows="1"
          onFocus={() => {
            props.setState("isTextareaFocused", true);
            if (props.state.isFirstMessage) {
              openFullScreenInput();
            }
          }}
          onBlur={() => props.setState("isTextareaFocused", false)}
          style={{ height: props.state.currentTextareaHeight }}
          onInput={autoGrowTextarea}
          value={props.state.isMessageLoading ? "" : props.state.userMessage}
          onChange={(e) => {
            const message = e.currentTarget.value;
            if (message.length <= maxCharLimit) {
              props.setState("userMessage", message);
              setCharacterCount(message.length);
            }
          }}
          disabled={props.state.isMessageLoading || props.state.isPollLoading}
        />
        <div class="absolute end-3 top-[0.4rem]">
          <button
            onClick={props.handleNewMessage}
            class={`flex h-9 w-9 items-center justify-center rounded-lg disabled:pointer-events-none disabled:opacity-50 ${
              props.state.isTextareaFocused
                ? "bg-white"
                : "bg-baseSecondaryDark"
            }`}
            disabled={
              props.state.isMessageLoading ||
              props.state.isPollLoading ||
              characterCount() > maxCharLimit
            }
          >
            <PhosphorIcon name="arrow-up" fontSize={20} size="bold" />
          </button>
        </div>
      </div>
      <Show when={props.state.isTextareaFocused}>
        <div
          class={`mt-0.5 text-end text-[10px] font-semibold ${
            characterCount() > maxCharLimit
              ? "text-errorDark"
              : "text-textNormal"
          }`}
        >
          {characterCount()}/{maxCharLimit}
        </div>
      </Show>
    </div>
  );
};
